import { AppHead } from "@/components/generic/app/AppHead";
import { Redirect } from "@/components/generic/navigation/Redirect";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import { withOptionalUser, WithUserProps } from "@/components/wrappers/WithUser";

const IndexPage: React.FC<WithUserProps> = ({ user }) => {
  let redirect = "/onboarding/start";
  if (user) {
    redirect = "/chat"; // change with chat
  }

  return (
    <>
      <AppHead title="Homepage" />
      <LayoutEmpty>
        <Redirect to={redirect} loading />
      </LayoutEmpty>
    </>
  );
};

export default withOptionalUser(IndexPage);
